import { browserName, osName, osVersion, browserVersion } from 'react-device-detect';
import { getDeviceType } from './http.hook';

export const sendError =  async (err, type) => {
  await fetch('https://logs.juun.ru/logs/logs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({
      project: 1,
      log_level: type,
      device_type: getDeviceType(),
      os: `${osName} ${osVersion}`,
      browser: `${browserName} ${browserVersion}`,
      message: err
    })
  });
}
