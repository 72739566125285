import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { Withdrawals, NotFound, Payment, DepositFailed } from '../Pages'


const router = createBrowserRouter([
  {
    path: "/",
    element: <Payment />,
    // errorElement: <NotFound />
  },
  {
    path: "/w",
    element: <Withdrawals />,
  },
  {
    path: "/faileddeposit",
    element: <DepositFailed />
  },
  {
    path: "/not-found",
    element: <NotFound />
  },
]);

function App() {
  return (
    <div className="App">
    </div>
  )
}

export { App, router }
