import React from 'react'
import { useRouteError } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './style.scss'
import { sendError } from '../../../hooks/err'

const NotFound = () => {
  const [t] = useTranslation()
  const error = useRouteError();
  const err = {page: "on error not found page", message: error?.data, stack: error?.error?.stack}
  sendError(JSON.stringify(err), "Error")
  return (
    <div className="container">
      <div className="error-page">
        <p className="error-text">ERROR</p>
        <div className="message">{t('error')}</div>
      </div>
    </div>
  )
}

export { NotFound }
