import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { sendError } from './hooks/err'

import { App, router } from './components/App'
import { Loading } from './components/Loading'

import './translations/i18n'
import './index.css'
const root = ReactDOM.createRoot(document.getElementById('root'))

window.onerror = function (message, source, lineno, colno, error) {
  sendError(JSON.stringify(message, source, lineno, colno, error), "Error")
  return true;
};

window.addEventListener("unhandledrejection", function (event) {
  sendError(JSON.stringify(event.reason), "Error")
});

sendError(JSON.stringify(window.location.href), "Info")

root.render(
  <RouterProvider router={router}>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </RouterProvider>
)
